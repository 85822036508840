<template>
  <div class="content">
    <h1>数据统一掌控</h1>
    <p>
      建立基础信息数据库，以此为基础进行扩容，融合各部门业务数据，进而形成大数据应用中心，从而实现数据统一掌控。
    </p>
    <div class="img1"></div>
    <div class="threeSolid">
      <div class="threeSolid_left"></div>
      <div class="threeSolid_right">
        <h3>房屋（场所）：</h3>
        <p>出租房、企业、学校、医院、复杂公共场所、特种行业场。</p>
        <h3>居民（人口）：</h3>
        <p>
          常住人口、流动人口、重点人员、两劳释放人员、在押在教人员、军转退休人员。
        </p>
        <h3>组织（单位）：</h3>
        <p>企事业单位、协会、党群等组织（单位）。</p>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 10%;
  margin-bottom: 50px;
  > h1 {
    font-family: PingFang SC;
    font-weight: 600;
    color: #000000;
    opacity: 1;
    margin: 30px 0 10px 0;
  }
  > p {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #333333;
    opacity: 1;
    margin: 20px 0;
  }
  .img1 {
    height: 700px;
    width: 100%;
    background: url("../../../../assets/image/theBasicLevel/img5.png") no-repeat;
    background-size: 100% 100%;
  }
  .threeSolid {
    display: flex;
    height: 400px;
    margin-top: 50px;
    .threeSolid_left {
      width: 25%;
      background: url("../../../../assets/image/theBasicLevel/组 107.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .threeSolid_right {
      width: 73%;
      margin-left: 30px;
      > h3 {
        margin: 30px 0;
      }
      > p {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #333;
        opacity: 1;
      }
    }
  }
}
</style>